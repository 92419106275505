import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

const Avatar = ({
  userInitial,
  link,
  summaryPage,
  isHeaderMenu,
  currentlySubscribed,
}) => (
  <Fragment>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a href={link} className={`avatar-button ${currentlySubscribed ? 'plus' : ''} ${summaryPage ? 'summary' : ''}`}>{!isHeaderMenu && <div className="label"><p className="text">{currentlySubscribed ? 'STV Player +' : 'Free'}</p></div>}<span data-testid="initial">{userInitial}</span></a>
    <style jsx>{styles}</style>
  </Fragment>
)

Avatar.propTypes = {
  userInitial: PropTypes.string,
  link: PropTypes.string,
  summaryPage: PropTypes.bool,
  isHeaderMenu: PropTypes.bool,
  currentlySubscribed: PropTypes.bool,
}

Avatar.defaultProps = {
  summaryPage: false,
  link: '',
  userInitial: 'S',
  isHeaderMenu: false,
  currentlySubscribed: false,
}

export default Avatar
